import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

import { api } from '@/api/api'
import type { Campaign, Promotion } from '@/types'
import { promotionApi } from '../promotion'
import {
  printException,
  stringifyPriceField,
  matchById,
  excludeById,
} from '@/api/api.helpers'

export const campaignApi = api.injectEndpoints({
  endpoints(builder) {
    return {
      getCampaign: builder.query<Campaign, number | undefined>({
        query(campaignId) {
          return `/campaign/${campaignId}`
        },
        providesTags: ['Campaign'],
      }),
      getCurrentCampaigns: builder.query<Campaign[], void>({
        query() {
          return '/current_campaigns'
        },
        providesTags: ['Campaign'],
      }),
      getArchivedCampaigns: builder.query<Campaign[], void>({
        query() {
          return '/archived_campaigns'
        },
        providesTags: ['Campaign'],
      }),
      getDraftCampaigns: builder.query<Campaign[], void>({
        query() {
          return '/draft_campaigns'
        },
        providesTags: ['Campaign'],
      }),
      addCampaignAndPromotion: builder.mutation<
        { campaign: Campaign; promotion: Promotion },
        number
      >({
        query(userClientId) {
          return {
            url: '/promotions/add_with_campaign',
            method: 'POST',
            body: {
              client_id: userClientId,
            },
          }
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            dispatch(
              campaignApi.util.updateQueryData(
                'getCurrentCampaigns',
                undefined,
                function (draft) {
                  draft.push(data.campaign)
                }
              )
            )
            dispatch(
              promotionApi.util.updateQueryData(
                'getCurrentPromotions',
                undefined,
                function (draft) {
                  draft.push(stringifyPriceField(data.promotion))
                }
              )
            )
          } catch (exception) {
            printException(
              exception,
              'Unknown exception : campaign and promotion creation'
            )
          }
        },
      }),
      editCampaign: builder.mutation<
        Campaign,
        Omit<Campaign, 'client_id' | 'sent'>
      >({
        query(campaign) {
          return {
            url: `/campaigns/${campaign.id}`,
            method: 'PUT',
            body: {
              campaign,
            },
          }
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          let currentCampaignsPatch, archivedCampaignsPatch, draftCampaignsPatch

          try {
            const { data } = await queryFulfilled

            const updateFn = (draft: any) => {
              const idx = draft.findIndex(matchById(data.id))
              draft[idx] = data
            }

            currentCampaignsPatch = dispatch(
              campaignApi.util.updateQueryData(
                'getCurrentCampaigns',
                undefined,
                updateFn
              )
            )
            archivedCampaignsPatch = dispatch(
              campaignApi.util.updateQueryData(
                'getArchivedCampaigns',
                undefined,
                updateFn
              )
            )
            draftCampaignsPatch = dispatch(
              campaignApi.util.updateQueryData(
                'getDraftCampaigns',
                undefined,
                updateFn
              )
            )
          } catch (exception) {
            currentCampaignsPatch?.undo()
            archivedCampaignsPatch?.undo()
            draftCampaignsPatch?.undo()
            printException(exception, 'Unknown exception : campaign edition')
          }
        },
      }),
      launchCampaign: builder.mutation<Campaign, Campaign>({
        async queryFn(arg, _queryApi, _extraOptions, fetchWithBaseQuery) {
          const updateCampaignRes = await fetchWithBaseQuery({
            url: `/campaigns/${arg.id}`,
            method: 'PUT',
            body: { campaign: { ...arg, sent: true } },
          })
          if (updateCampaignRes.error)
            return { error: updateCampaignRes.error as FetchBaseQueryError }
          const campaign = updateCampaignRes.data as Campaign
          const sendMailRes = await fetchWithBaseQuery({
            url: '/mails',
            method: 'POST',
            body: { campaign_id: campaign.id },
          })
          if (sendMailRes.error)
            return {
              error: sendMailRes.error as FetchBaseQueryError,
            }
          return { data: campaign }
        },
        async onQueryStarted(campaign, { dispatch }) {
          try {
            dispatch(
              campaignApi.util.updateQueryData(
                'getDraftCampaigns',
                undefined,
                function (draft) {
                  return draft.filter(excludeById(campaign.id))
                }
              )
            )
          } catch (e) {
            console.error(e)
          }
        },
      }),
      addCampaign: builder.mutation<Campaign, number>({
        query(clientId) {
          return {
            url: '/campaigns',
            method: 'POST',
            body: {
              client_id: clientId,
            },
          }
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            dispatch(
              campaignApi.util.updateQueryData(
                'getDraftCampaigns',
                undefined,
                function (draft) {
                  draft.push(data)
                }
              )
            )
          } catch (exception) {
            printException(exception, 'Unknown exception : campaign creation')
          }
        },
      }),
    }
  },
})

export const {
  useGetCampaignQuery,
  useGetCurrentCampaignsQuery,
  useGetArchivedCampaignsQuery,
  useGetDraftCampaignsQuery,
  useAddCampaignAndPromotionMutation,
  useEditCampaignMutation,
  useLaunchCampaignMutation,
  useAddCampaignMutation,
} = campaignApi
