import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

import { fetchUser } from '../user/user.slice'
import type { ErrorMessage, Status } from '@/types'

interface LoginHandlerArgs {
  email: string
  password: string
}

export const login = createAsyncThunk<
  void,
  LoginHandlerArgs,
  { rejectValue: { error: { message: string } } }
>('auth/login', async ({ email, password }, { rejectWithValue, dispatch }) => {
  try {
    const res = await fetch('/api/auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: email,
        password: password,
      }),
    })

    if (!res.ok) {
      throw new Error(`Login failed with status ${res.status}`)
    }

    const jwt = res.headers.get('Authorization')
    if (!jwt) throw new Error('Failed to get an access token')

    Cookies.set('jwt', jwt, { path: '/' })
    await dispatch(fetchUser()).unwrap()
  } catch (err) {
    const error = err as Error
    console.error(error)
    return rejectWithValue({
      error: { message: error.message || 'Login request failed' },
    })
  }
})

interface AuthStatus {
  status: Status
  error: ErrorMessage
}

const initialState: AuthStatus = {
  status: 'idle',
  error: null,
}

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch }) => {
    Cookies.remove('jwt', { path: '/' })
    await dispatch({ type: 'CLEAR_STATE' })
    return { ...initialState }
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.status = 'pending'
        state.error = null
      })
      .addCase(login.fulfilled, state => {
        state.status = 'success'
        state.error = null
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'error'
        state.error = action.payload?.error.message
      })
  },
})

const authReducer = authSlice.reducer

export const { caseReducers } = authSlice

export default authReducer
