import type { FC } from 'react'

import { BREADCRUMB_CONTAINER_ID } from '../breadcrumb/constants'
import styles from './breadcrumb-container.module.scss'

import { useIsIndie } from '@/utils/use-is-indie'

interface Props {
  id?: string
}

const BreadcrumbContainer: FC<Props> = ({ id }) => {
  const isIndie = useIsIndie()

  if (isIndie) {
    // not null to keep the searchbar alignment
    return <div />
  }

  return (
    <nav aria-label="breadcrumb" className={styles.root}>
      <ol className={styles.ol} id={id ?? BREADCRUMB_CONTAINER_ID} />
    </nav>
  )
}

export default BreadcrumbContainer
