import { useRouter } from 'next/router'
import { useEffect, FC, ReactNode, useState } from 'react'

import { User } from './user.slice'
import { ROUTES } from '@/constants'
import AuthenticatedLayout from '@/shared/layouts/authenticated-layout'
import AppHead from '@/shared/head'

type UserAccess = 'allowed' | 'not-allowed'

const getUserAccess = ({
  userRole,
  path,
}: {
  userRole: User['role']
  path: string
}): UserAccess => {
  if (ROUTES.hasOwnProperty(path)) {
    return ROUTES[path as keyof typeof ROUTES].access.includes(userRole)
      ? 'allowed'
      : 'not-allowed'
  } else {
    return 'allowed'
  }
}

const PermissionShell: FC<{ children: ReactNode; userRole: User['role'] }> = ({
  children,
  userRole,
}) => {
  const [userAccess, setUserAccess] = useState<UserAccess>('not-allowed')

  const { pathname } = useRouter()

  useEffect(() => {
    setUserAccess(getUserAccess({ userRole, path: pathname }))
  }, [userRole, pathname])

  switch (userAccess) {
    case 'allowed': {
      return <>{children}</>
    }
    case 'not-allowed':
      return (
        <AuthenticatedLayout>
          <AppHead subtitle="Non autorisé"></AppHead>
          <p role="alert" style={{ textAlign: 'center' }}>
            Vous n&apos;avez pas la permission
          </p>
        </AuthenticatedLayout>
      )
    default:
      throw new Error('Impossible state')
  }
}

export default PermissionShell
