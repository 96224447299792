import { ButtonHTMLAttributes, FC, ReactNode } from 'react'
import styles from './icon-button.module.scss'

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ReactNode
  className?: string
}

const IconButton: FC<IconButtonProps> = ({
  icon,
  className: _className,
  ...props
}) => {
  const className = _className
    ? `${styles.button} ${_className}`
    : `${styles.button}`
  return (
    <button {...props} className={className}>
      {icon}
    </button>
  )
}

export default IconButton
