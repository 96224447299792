export function groupBy<T extends Record<string, any>>(obj: T[], prop: string) {
  return obj.reduce((accumulator, current) => {
    const category = current[prop]
    if (accumulator.has(category)) {
      accumulator.get(category)?.push(current)
    } else {
      accumulator.set(category, [current])
    }
    return accumulator
  }, new Map<string, T[]>())
}

export default groupBy
