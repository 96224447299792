import { api } from '@/api/api'
import { matchById, printException } from '@/api/api.helpers'
import type { ClientPromotion } from '@/types'

export const clientPromotionApi = api.injectEndpoints({
  endpoints(builder) {
    return {
      getClientPromotions: builder.query<ClientPromotion[], void>({
        query() {
          return '/client_promotions'
        },
        providesTags: ['Client Promotions'],
      }),
      updateClientPromotion: builder.mutation<
        ClientPromotion,
        Omit<ClientPromotion, 'price' | 'id'> & { price: string }
      >({
        query(clientPromotion) {
          return {
            url: '/client_promotions',
            method: 'POST',
            body: {
              clientPromotion,
            },
          }
        },
        invalidatesTags: ['Client Promotions'],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            dispatch(
              clientPromotionApi.util.updateQueryData(
                'getClientPromotions',
                undefined,
                function (draft) {
                  const idx = draft.findIndex(matchById(data.id))
                  draft[idx] = data
                }
              )
            )
          } catch (exception) {
            printException(
              exception,
              'Unknown exception : client promotion edition'
            )
          }
        },
      }),
    }
  },
})

export const { useGetClientPromotionsQuery, useUpdateClientPromotionMutation } =
  clientPromotionApi
