import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const RADIX = 36

interface PosterUrlKeyState {
  key: string
}

const initialState: PosterUrlKeyState = {
  // key is used to disable image caching by appending it to poster url
  key: Math.random().toString(RADIX),
}

const posterUrlKeySlice = createSlice({
  name: 'posterUrlKey',
  initialState,
  reducers: {
    generateNewKey: (state, action: PayloadAction<string>) => {
      state.key = action.payload
    },
  },
})

const posterUrlKeyReducer = posterUrlKeySlice.reducer

export const { generateNewKey } = posterUrlKeySlice.actions

export default posterUrlKeyReducer
