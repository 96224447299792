import { FC, ReactNode } from 'react'

import styles from './icon.module.scss'

interface IconProps {
  icon: ReactNode
  focusable?: 'true' | 'false'
  size?: 'small' | 'medium' | 'large' | 'big'
}

const Icon: FC<IconProps> = ({ icon, size = 'medium', ...rest }) => {
  return (
    <span className={`${styles.root} ${styles[size]}`} {...rest}>
      {icon}
    </span>
  )
}

export default Icon
