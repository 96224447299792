import Cookies from 'js-cookie'

const fetcher = async <T>(url: string, options?: RequestInit) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${Cookies.get('jwt')}`,
      'Content-Type': 'application/json',
    },
    ...options,
  })
  if (response.ok) return (await response.json()) as T

  throw new Error(`Request failed with status ${response.status}`)
}

export const blobFetcher = async (url: string, options?: RequestInit) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${Cookies.get('jwt')}`,
      'Content-Type': 'application/json',
    },
    ...options,
  })
  if (response.ok) return await response.blob()

  throw new Error(`Request failed with status ${response.status}`)
}

export default fetcher
