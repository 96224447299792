import Cookies from 'js-cookie'

import type { PromotionOnClient, Promotion } from '@/types'
import { numberToPrice, priceToNumber } from '@/utils/price'

export const prepareHeaders = (headers: Headers) => {
  const token = Cookies.get('jwt')
  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }
  headers.set('Content-Type', 'application/json')
  return headers
}

export function matchById<T extends { id: number }>(id: number) {
  return function (entity: T) {
    return entity.id === id
  }
}

export function excludeById<T extends { id: number }>(id: number) {
  return function (entity: T) {
    return entity.id !== id
  }
}

export function stringifyPriceField({
  price,
  ...rest
}: Promotion): PromotionOnClient {
  return {
    ...rest,
    price: numberToPrice(price),
  }
}

export function parsePriceField({
  price,
  ...rest
}: PromotionOnClient): Promotion {
  return {
    ...rest,
    price: priceToNumber(price),
  }
}

export function printException(exception: unknown, customMessage?: string) {
  let errorMessage = customMessage ?? 'Unknown exception'
  if (exception instanceof Error) errorMessage = exception.message
  console.error(errorMessage)
}
