import { FC } from 'react'
import Link from 'next/link'

import styles from './footer.module.scss'

const Footer: FC = () => {
  return (
    <footer className={styles.footer}>
      <p>
        <Link href="https://www.promomaker.fr/">Promomaker</Link> par{' '}
        <Link href="https://kozea.fr">Kozea</Link>
      </p>
    </footer>
  )
}

export default Footer
