import { FC } from 'react'

import type { CartElement } from '@/types'
import { modifyQuantity } from './cart.slice'
import { useDispatch } from '@/app/store'
import Input from '@/shared/input'

const ModifyElementQuantity: FC<{
  currentQuantity: number
  cartElement: CartElement
  cartFormId: string
}> = ({ cartElement, currentQuantity }) => {
  const dispatch = useDispatch()
  const { type, id, format } = cartElement
  return (
    <Input
      type="number"
      name={
        `${type}-${id}` + `${type === 'promotion' ? `-${format?.slug}` : ''}`
      }
      value={currentQuantity}
      min={0}
      onChange={evt => {
        const { value } = evt.target
        if (value.length === 0) return
        const newQuantity = Number(evt.target.value)
        dispatch(modifyQuantity({ element: cartElement, newQuantity }))
      }}
    >
      Quantité
    </Input>
  )
}

export default ModifyElementQuantity
