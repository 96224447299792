import { api } from '@/api/api'

export const accountApi = api.injectEndpoints({
  endpoints(builder) {
    return {
      updatePassword: builder.mutation<void, { password: string }>({
        query({ password }) {
          return {
            url: '/change_password',
            method: 'POST',
            body: { password },
          }
        },
      }),
    }
  },
})

export const { useUpdatePasswordMutation } = accountApi
