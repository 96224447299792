/* eslint-disable max-len */
import { SVGProps } from 'react'

const DocumentSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M45 25H20a1 1 0 1 0 0 2h25a1 1 0 1 0 0-2zM20 19h10a1 1 0 1 0 0-2H20a1 1 0 1 0 0 2zM45 33H20a1 1 0 1 0 0 2h25a1 1 0 1 0 0-2zM45 41H20a1 1 0 1 0 0 2h25a1 1 0 1 0 0-2zM45 49H20a1 1 0 1 0 0 2h25a1 1 0 1 0 0-2z" />
    <path d="M49 14.586V0H6v55h5v5h43V19.586l-5-5zm-9-6.172 9 9L50.586 19H40V8.414zM8 53V2h39v10.586L39.414 5H11v48H8zm5 5V7h25v14h14v37H13z" />
  </svg>
)

export default DocumentSVG
