import { FC, ElementType, ReactNode } from 'react'

import styles from './form-control.module.scss'

interface FormControlProps {
  as?: ElementType
  children: ReactNode
}

const FormControl: FC<FormControlProps> = ({ children, as = 'div' }) => {
  const Component = as
  return <Component className={styles['form-control']}>{children}</Component>
}

export default FormControl
