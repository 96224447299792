import { useEffect } from 'react'

import useClient from './use-client.hook'

/**
 * Sets dynamically the CSS variable `var(--path-to-client-logo)`
 * to allow the app to use the right logo
 */
const useClientLogo = () => {
  const { client } = useClient()

  useEffect(() => {
    if (client) {
      const root = document.documentElement
      root.style.setProperty(
        '--path-to-client-logo',
        `url(/images/themes/${client.domain}/logo.png)`
      )
    }
  }, [client])
}

export default useClientLogo
