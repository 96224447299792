import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { FC } from 'react'

import styles from './dropdown-menu.module.scss'

const {
  Root: RootPrimitive,
  Trigger: TriggerPrimitive,
  Content,
  Item: ItemPrimitive,
} = DropdownMenuPrimitive

export const Item = ItemPrimitive

export const Root: FC<{
  children: JSX.Element | JSX.Element[]
  Trigger: JSX.Element
  contentClassName?: string
}> = ({ children, Trigger, contentClassName }) => {
  return (
    <RootPrimitive modal={false}>
      <TriggerPrimitive asChild>{Trigger}</TriggerPrimitive>
      <Content
        align="end"
        className={`${styles.content} ${contentClassName ?? ''}`}
        sideOffset={5}
        loop
      >
        {children}
      </Content>
    </RootPrimitive>
  )
}
