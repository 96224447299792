const debounce = <T, U>(cb: (arg: T) => U, wait: number) => {
  let timeoutID: NodeJS.Timeout | null = null
  const callable = (arg: T) => {
    if (timeoutID) clearTimeout(timeoutID)
    timeoutID = setTimeout(() => {
      cb(arg)
      timeoutID = null
    }, wait)
  }
  return callable
}

export { debounce }
export default debounce
