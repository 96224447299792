import { FC } from 'react'
import AppHead from '@/shared/head'

import styles from './login-layout.module.scss'

interface Props {
  children: JSX.Element | JSX.Element[]
}

const LoginLayout: FC<Props> = ({ children }) => {
  return (
    <>
      <AppHead subtitle="Se connecter"></AppHead>
      <main className={styles.root}>{children}</main>
    </>
  )
}
export default LoginLayout
