import type { CartElement, ElementToAddInCart } from '@/types'

export const countCartElement = (cart: CartElement[]) => {
  return cart.reduce((acc, current) => acc + current.quantity, 0)
}

export const computeCartLengthLabel = (cartLength: number) => {
  let cartLengthLabel = ''
  if (cartLength === 0) cartLengthLabel = "Aucun document prêt à l'impression"
  else if (cartLength === 1)
    cartLengthLabel = `${cartLength} document prêt à l'impression`
  else if (cartLength > 1)
    cartLengthLabel = `${cartLength} documents prêts à l'impression`
  else {
    throw new Error("Cart lenght can't be negative")
  }
  return cartLengthLabel
}

export const findElementIndex = (
  elementToFind: ElementToAddInCart,
  cart: CartElement[]
) =>
  cart.findIndex(
    ({ id, format, type }) =>
      id === elementToFind.id &&
      format?.slug === elementToFind.format?.slug &&
      type === elementToFind.type
  )
