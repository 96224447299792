import 'react-datepicker/dist/react-datepicker.css'
import { Provider as ReduxProvider } from 'react-redux'
import { Toaster } from 'react-hot-toast'

import '@/styles/reset.css'
import '@/styles/global.css'
import '@/styles/variables.css'
import '@/styles/fonts.css'
import store from '@/app/store'
import ApplicationShell from '@/feature/user/application-shell'
import type { AppPropsWithLayout } from '@/types'
import AuthenticatedLayout from '@/shared/layouts/authenticated-layout'

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // If Component has its own layout, use it. Otherwise, use the default layout
  const getLayout =
    Component.getLayout ??
    (page => <AuthenticatedLayout>{page}</AuthenticatedLayout>)

  return (
    <ReduxProvider store={store}>
      <ApplicationShell>
        {getLayout(<Component {...pageProps} />)}
      </ApplicationShell>
      <Toaster
        toastOptions={{
          duration: 5000,
        }}
        position="bottom-right"
      />
    </ReduxProvider>
  )
}

export default MyApp
