import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import fetcher from '@/utils/fetcher'
import type { ErrorMessage, Status } from '@/types'

interface Client {
  domain: string
  groupId: number | null
  id: number
  name: string
  posterStyle: string
  template: string
}

interface RawClient extends Omit<Client, 'groupId' | 'posterStyle'> {
  group_id: number | null
  poster_style: string
}

export const fetchClient = createAsyncThunk<
  Client,
  void,
  { rejectValue: { error: { message: string } } }
>('client/fetchClient', async (_, { rejectWithValue }) => {
  try {
    const {
      domain,
      group_id: groupId,
      id,
      name,
      poster_style: posterStyle,
      template,
    } = await fetcher<RawClient>('/api/client')
    return { domain, groupId, id, name, posterStyle, template }
  } catch (err) {
    const error = err as Error
    return rejectWithValue({
      error: {
        message: error?.message || 'Get client request failed',
      },
    })
  }
})

interface ClientState {
  client: Client | null
  status: Status
  error: ErrorMessage
}
const initialState: ClientState = {
  client: null,
  status: 'idle',
  error: null,
}

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    removeClient: () => ({ ...initialState }),
  },
  extraReducers: builder => {
    builder
      .addCase(fetchClient.pending, state => {
        state.status = 'pending'
        state.error = null
        state.client = null
      })
      .addCase(fetchClient.fulfilled, (state, action) => {
        state.status = 'success'
        state.error = null
        state.client = action.payload
      })
      .addCase(fetchClient.rejected, (state, action) => {
        state.status = 'error'
        state.error = action.payload?.error.message
        state.client = null
      })
  },
})

const clientReducer = clientSlice.reducer

export default clientReducer
