import { FC } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import styles from './navbar.module.scss'
import Icon from '@/shared/icon'
import { logout } from '../auth/auth.slice'
import { User } from '../user/user.slice'
import { useDispatch, useSelector } from '@/app/store'
import * as DropdownMenu from '@/shared/dropdown-menu'
import { CartLink } from '../cart'
import { NAVLINKS } from '@/constants'
import { useIsIndie } from '@/utils/use-is-indie'

const Navbar: FC = () => {
  const { pathname, push } = useRouter()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.user) as User
  const isIndie = useIsIndie()

  const { isAdmin, mail, role } = user
  return (
    <nav className={styles.root}>
      <Link href="/" className={styles.logo}>
        <span hidden>Logo</span>
      </Link>
      <ul className={styles.navlinks}>
        {NAVLINKS[role].map(({ href, activeAt, text, name, icon }) => {
          const isActive = activeAt.includes(pathname)

          if (isIndie && name === 'document') {
            return null
          }

          return (
            <li key={name}>
              <Link
                href={href}
                className={`${styles.navlink} ${
                  isActive ? styles['navlink--active'] : ''
                }`}
              >
                <Icon icon={icon} aria-hidden="true" focusable="false" />
                {text}
              </Link>
            </li>
          )
        })}
      </ul>
      <aside className={styles.aside}>
        <DropdownMenu.Root
          contentClassName={styles.dropdown}
          Trigger={
            <button className={styles['dropdown-trigger']}>{`${mail} (${
              isAdmin ? 'Groupement' : 'Pharmacie'
            }) ▼`}</button>
          }
        >
          <DropdownMenu.Item className={styles['dropdown-item']} asChild>
            <button
              className={styles['go-to-account']}
              onClick={() => push('/account')}
            >
              Mon compte
            </button>
          </DropdownMenu.Item>
          <DropdownMenu.Item asChild className={styles['dropdown-item']}>
            <button
              onClick={async () => {
                dispatch(logout())
              }}
              className={styles.logout}
            >
              Se déconnecter
            </button>
          </DropdownMenu.Item>
        </DropdownMenu.Root>
        {role === 'pharmacy' && <CartLink />}
      </aside>
    </nav>
  )
}

export default Navbar
