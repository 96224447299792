import Head from 'next/head'
import { FC } from 'react'

import useClient from '@/feature/client/use-client.hook'

interface HeadProps {
  subtitle?: string
  children?: JSX.Element | string
}

const AppHead: FC<HeadProps> = ({ subtitle, children }) => {
  const { client } = useClient()
  const faviconPath = client
    ? `/images/themes/${client.domain}/favicon.png`
    : '/images/favicon.png'
  return (
    <Head>
      <link rel="icon" href={faviconPath} />
      <title>Promomaker{subtitle ? ` - ${subtitle}` : ''}</title>

      {children}
    </Head>
  )
}

export default AppHead
