/* eslint-disable max-len */
import { SVGProps } from 'react'

const ArchiveSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M57.49 27H54v-6.268A2.736 2.736 0 0 0 51.268 18H48v-2.414l-.495-.495-.001-.002-7.296-7.296L32.414 0H8v11H2.732A2.736 2.736 0 0 0 0 13.732v43.687h.006c-.005.563.17 1.114.522 1.575A2.546 2.546 0 0 0 2.565 60h44.759c1.156 0 2.174-.779 2.45-1.813L60 30.149v-.177C60 28.25 58.944 27 57.49 27zm-6.222-7c.403 0 .732.328.732.732V27h-4v-7h3.268zM33 3.415 44.586 15H33V3.415zM10 2h21v15h15v10H12.731c-.143 0-.284.012-.422.035-.974.162-1.786.872-2.028 1.778l-.281.772V2zM2 13.732c0-.404.329-.732.732-.732H8v22.035L2 51.399V13.732zm45.869 43.851a.565.565 0 0 1-.545.417H2.565a.552.552 0 0 1-.448-.222c-.063-.082-.16-.256-.123-.408L8 40.898v.005l4.16-11.404.026-.082a.566.566 0 0 1 .545-.417H57.49c.38 0 .477.546.502.819L47.869 57.583z" />
    <path d="M16 17h10a1 1 0 1 0 0-2H16a1 1 0 1 0 0 2zM16 10h10a1 1 0 1 0 0-2H16a1 1 0 1 0 0 2zM16 24h24a1 1 0 1 0 0-2H16a1 1 0 1 0 0 2z" />
  </svg>
)

export default ArchiveSVG
