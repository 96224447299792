import { FC } from 'react'

import AppHead from '@/shared/head'
import styles from './authenticated-layout.module.scss'
import BreadcrumbContainer from '../../breadcrumb-container'
import Navbar from '@/feature/navbar'
import Footer from '../../footer'
import Searchbar from '@/feature/search-bar'
import { useRouter } from 'next/router'
import { SEARCHBAR_ALLOWED_PAGES } from '@/constants'

interface Props {
  children: JSX.Element | JSX.Element[]
}
const AuthenticatedLayout: FC<Props> = ({ children }) => {
  const { pathname } = useRouter()
  return (
    <>
      <AppHead></AppHead>
      <section className={styles['app-wrapper']}>
        <Navbar />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 'var(--space-5)',
          }}
        >
          <BreadcrumbContainer />
          {SEARCHBAR_ALLOWED_PAGES.includes(pathname) && <Searchbar />}
        </div>
        <main className={styles['app-main']}>{children}</main>
        <Footer />
      </section>
    </>
  )
}

export default AuthenticatedLayout
