import { api } from '@/api/api'
import type { PromotionOnClient, Promotion } from '@/types'
import {
  stringifyPriceField,
  parsePriceField,
  printException,
  matchById,
  excludeById,
} from '@/api/api.helpers'

export const promotionApi = api.injectEndpoints({
  endpoints(builder) {
    return {
      getPromotion: builder.query<PromotionOnClient, number>({
        query(promotionId) {
          return `/promotion/${promotionId}`
        },
        transformResponse(result: Promotion) {
          if (result) return stringifyPriceField(result)
          return result
        },
        providesTags: (result, error, arg) => [{ type: 'Promotion', id: arg }],
      }),
      getCurrentPromotions: builder.query<PromotionOnClient[], void>({
        query() {
          return '/current_promotions'
        },
        providesTags: ['Promotion'],
        transformResponse(result: Promotion[]) {
          if (result) return result.map(stringifyPriceField)
          return result
        },
      }),
      getArchivedPromotions: builder.query<PromotionOnClient[], void>({
        query() {
          return '/archived_promotions'
        },
        providesTags: ['Promotion'],
        transformResponse(result: Promotion[]) {
          if (result) return result.map(stringifyPriceField)
          return result
        },
      }),
      getDraftPromotions: builder.query<PromotionOnClient[], void>({
        query() {
          return '/draft_promotions'
        },
        providesTags: ['Promotion'],
        transformResponse(result: Promotion[]) {
          if (result) return result.map(stringifyPriceField)
          return result
        },
      }),
      addPromotion: builder.mutation<Promotion, number>({
        query(campaignId) {
          return {
            url: '/promotions',
            method: 'POST',
            body: {
              campaign_id: campaignId,
            },
          }
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            const updateFn = (draft: any) => {
              draft.push(stringifyPriceField(data))
            }

            dispatch(
              promotionApi.util.updateQueryData(
                'getCurrentPromotions',
                undefined,
                updateFn
              )
            )
            dispatch(
              promotionApi.util.updateQueryData(
                'getArchivedPromotions',
                undefined,
                updateFn
              )
            )
            dispatch(
              promotionApi.util.updateQueryData(
                'getDraftPromotions',
                undefined,
                updateFn
              )
            )
          } catch (exception) {
            printException(exception, 'Unkown exception : promotion creation')
          }
        },
      }),
      addPromotionBatch: builder.mutation<
        Promotion[],
        { campaignId: number; content: string }
      >({
        query({ campaignId, content }) {
          return {
            url: '/promotions-batch',
            method: 'POST',
            body: {
              campaign_id: campaignId,
              content,
            },
          }
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            const updateFn = (draft: any) => {
              draft.push(...data.map(stringifyPriceField))
            }

            dispatch(
              promotionApi.util.updateQueryData(
                'getCurrentPromotions',
                undefined,
                updateFn
              )
            )
            dispatch(
              promotionApi.util.updateQueryData(
                'getDraftPromotions',
                undefined,
                updateFn
              )
            )
          } catch (exception) {
            printException(exception, 'Unknown exception : batch creation')
          }
        },
      }),
      deletePromotion: builder.mutation<void, number>({
        query(promotionId) {
          return {
            url: `/promotions/${promotionId}`,
            method: 'DELETE',
          }
        },
        async onQueryStarted(promotionId, { dispatch, queryFulfilled }) {
          const updateFn = (draft: any) => {
            return draft.filter(excludeById(promotionId))
          }
          const currentPromotionPatch = dispatch(
            promotionApi.util.updateQueryData(
              'getCurrentPromotions',
              undefined,
              updateFn
            )
          )
          const archivedPromotionPatch = dispatch(
            promotionApi.util.updateQueryData(
              'getArchivedPromotions',
              undefined,
              updateFn
            )
          )
          const draftPromotionPatch = dispatch(
            promotionApi.util.updateQueryData(
              'getDraftPromotions',
              undefined,
              updateFn
            )
          )
          try {
            await queryFulfilled
          } catch (exception) {
            currentPromotionPatch.undo()
            archivedPromotionPatch.undo()
            draftPromotionPatch.undo()
            printException(exception, 'Unkown exception : promotion deletion')
          }
        },
      }),
      updatePromotion: builder.mutation<Promotion, PromotionOnClient>({
        query(promotion) {
          return {
            url: `/promotions/${promotion.id}`,
            method: 'PUT',
            body: {
              promotion: parsePriceField(promotion),
            },
          }
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            const updateFn = (draft: any) => {
              const idx = draft.findIndex(matchById(data.id))
              draft[idx] = stringifyPriceField(data)
            }
            dispatch(
              promotionApi.util.updateQueryData(
                'getCurrentPromotions',
                undefined,
                updateFn
              )
            )
            dispatch(
              promotionApi.util.updateQueryData(
                'getArchivedPromotions',
                undefined,
                updateFn
              )
            )
            dispatch(
              promotionApi.util.updateQueryData(
                'getDraftPromotions',
                undefined,
                updateFn
              )
            )
          } catch (exception) {
            printException(exception, 'Unknown exception : promotion edition')
          }
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'Promotion', id: arg.id },
        ],
      }),
    }
  },
})

export const {
  useGetPromotionQuery,
  useGetCurrentPromotionsQuery,
  useGetArchivedPromotionsQuery,
  useGetDraftPromotionsQuery,
  useAddPromotionBatchMutation,
  useAddPromotionMutation,
  useUpdatePromotionMutation,
  useDeletePromotionMutation,
} = promotionApi
