import { api } from '@/api/api'
import type { Catchphrase } from '@/types'

export const catchphraseApi = api.injectEndpoints({
  endpoints(builder) {
    return {
      getCatchphrases: builder.query<Catchphrase[], void>({
        query() {
          return '/catchphrases'
        },
        providesTags: ['Catchphrase'],
      }),
    }
  },
})

export const { useGetCatchphrasesQuery } = catchphraseApi
