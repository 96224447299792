import { FC, ReactNode, useEffect } from 'react'

import { useDispatch, useSelector } from '@/app/store'
import PermissionShell from './permission-shell'
import { fetchUser } from './user.slice'
import LoginLayout from '@/shared/layouts/login-layout'
import LoginForm from '../auth/login-form/login-form'
import useClientLogo from '../client/use-client-logo.hook'

const ApplicationShell: FC<{ children: ReactNode }> = ({ children }) => {
  useClientLogo()

  const { user, status } = useSelector(state => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    if (status === 'idle') dispatch(fetchUser())
  }, [status, dispatch])

  switch (status) {
    case 'idle':
    case 'pending':
      return <p role="progressbar">Chargement...</p>
    case 'error':
    case 'success': {
      if (user) {
        return (
          <PermissionShell userRole={user.role}>{children}</PermissionShell>
        )
      }

      return (
        <LoginLayout>
          <LoginForm />
        </LoginLayout>
      )
    }
    default:
      throw new Error('Impossible state')
  }
}

export default ApplicationShell
