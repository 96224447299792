import { ChangeEvent, FC, useMemo, useCallback } from 'react'
import Label from '@/shared/label'
import styles from './search-bar.module.scss'
import { useDispatch } from '@/app/store'
import { setSearch } from './search-bar.slice'
import { debounce } from '@/utils/debounce'

const useOnChangeDebounced = () => {
  const dispatch = useDispatch()
  const onChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const { value } = evt.target
      dispatch(setSearch(value))
    },
    [dispatch]
  )
  return useMemo(() => debounce(onChange, 500), [onChange])
}

const Searchbar: FC = () => {
  const onChangeDebounced = useOnChangeDebounced()
  return (
    <div className={styles.root}>
      <Label htmlFor="search">Recherche</Label>
      <input name="search" type="search" onChange={onChangeDebounced} />
    </div>
  )
}

export default Searchbar
