import { api } from '@/api/api'
import type { Badge } from '@/types'

export const badgeApi = api.injectEndpoints({
  endpoints(builder) {
    return {
      getBadges: builder.query<Badge[], void>({
        query() {
          return '/badges'
        },
        providesTags: ['Badge'],
      }),
    }
  },
})

export const { useGetBadgesQuery } = badgeApi
