import { useEffect } from 'react'

import { useDispatch, useSelector } from '@/app/store'
import { fetchClient } from './client.slice'

const useClient = () => {
  const dispatch = useDispatch()
  const { client, status, error } = useSelector(state => state.client)
  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchClient())
    }
  }, [status, dispatch])

  return { client, status, error }
}

export default useClient
