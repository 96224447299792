import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from './api.helpers'

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders,
  }),
  tagTypes: [
    'Badge',
    'Campaign',
    'Catchphrase',
    'Client Promotions',
    'Client variables',
    'Communication',
    'Document',
    'Poster formats',
    'Promotion',
    'Promotion Types',
    'Promotion Badges',
    'Promotion variables',
  ],
  endpoints: () => ({}),
})
