import { FC, useRef } from 'react'

import styles from './login-form.module.scss'
import Input from '@/shared/input'
import { login } from '../auth.slice'
import { useSelector, useDispatch } from '@/app/store'

const LoginForm: FC = () => {
  const { status: loginStatus } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const emailInputRef = useRef<HTMLInputElement>(null)
  const passwordInputRef = useRef<HTMLInputElement>(null)

  return (
    <form
      className={styles.form}
      onSubmit={async evt => {
        evt.preventDefault()
        const email = emailInputRef.current?.value || ''
        const password = passwordInputRef.current?.value || ''
        if (email && password) {
          await dispatch(login({ email, password }))
        }
      }}
    >
      <Input ref={emailInputRef} name="email" type="email" required>
        Adresse email
      </Input>
      <Input name="password" type="password" required ref={passwordInputRef}>
        Mot de passe
      </Input>
      <button
        disabled={loginStatus === 'pending'}
        className={styles['submit-btn']}
        type="submit"
      >
        {loginStatus === 'pending' ? 'Connexion...' : 'Se connecter'}
      </button>
      {loginStatus === 'error' ? (
        <span className={styles.alert} role="alert">
          La connexion a échoué
        </span>
      ) : (
        <span className={styles.spacer} />
      )}
    </form>
  )
}

export default LoginForm
