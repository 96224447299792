import { FC } from 'react'

import styles from './heading.module.scss'

interface HeadingProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children: JSX.Element | string
  className?: string
}

const Heading: FC<HeadingProps> = ({ children, as = 'h2', className = '' }) => {
  const Component = as
  return (
    <Component
      className={className ? styles.root + ' ' + className : styles.root}
    >
      {children}
    </Component>
  )
}

export default Heading
