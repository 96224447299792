import { useEffect, useState } from 'react'
import useClient from '@/feature/client/use-client.hook'
import useUserRole from '@/feature/user/use-user-role.hook'

export function useIsIndie() {
  const [isIndie, setIsIndie] = useState<boolean | undefined>(undefined)
  const { client } = useClient()
  const userRole = useUserRole()

  useEffect(() => {
    if (client && userRole) {
      setIsIndie(client.domain === 'kozea' && userRole === 'pharmacy')
    }
  }, [client, userRole])

  return isIndie
}
