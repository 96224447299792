import {
  createSlice,
  PayloadAction,
  //current
} from '@reduxjs/toolkit'

import type { CartElement, ElementToAddInCart } from '@/types'
import { findElementIndex } from './cart.helpers'
import migrateLegacyCart from './migrate-legacy-cart'

const initialState = [] as CartElement[]

export const LS_KEY_CART = 'cart.2'

const cartSlice = createSlice({
  name: 'cart',
  initialState: () => {
    if (typeof window === 'undefined') return initialState
    /*
     * Migrate persisted cart object from legacy app to nextJS
     * TODO: To remove 6 months after NextJS deploy.
     */
    // @ts-ignore https://stackoverflow.com/a/67700426
    const legacyCart = JSON.parse(localStorage.getItem('cart'))
    // @ts-ignore https://stackoverflow.com/a/67700426
    const cart = JSON.parse(localStorage.getItem(LS_KEY_CART))

    let initialCart: CartElement[] = []
    if (legacyCart && !cart) {
      initialCart = migrateLegacyCart(legacyCart)
    } else if (cart) {
      initialCart = cart
    }
    return initialCart
  },
  reducers: {
    addToCart: (cart, action: PayloadAction<ElementToAddInCart>) => {
      const { payload } = action
      const elementToFind = payload
      const idx = findElementIndex(elementToFind, cart)

      if (idx !== -1) {
        cart[idx].quantity++
      } else {
        cart.push({ ...payload, quantity: 1 })
      }
    },
    modifyQuantity: (
      cart,
      action: PayloadAction<{
        element: ElementToAddInCart
        newQuantity: number
      }>
    ) => {
      const { element: elementToFind, newQuantity } = action.payload
      const idx = findElementIndex(elementToFind, cart)
      if (idx === -1) return cart

      if (newQuantity === 0) return cart.filter((_, index) => index !== idx)
      else
        return cart.map((prev, index) => {
          if (index === idx) return { ...prev, quantity: newQuantity }
          return prev
        })
    },
    emptyCart: () => {
      return initialState
    },
    removeFromCart: (cart, action: PayloadAction<ElementToAddInCart>) => {
      const elementToFind = action.payload
      const idx = findElementIndex(elementToFind, cart)
      if (idx === -1) return cart
      return cart.filter((_, index) => index !== idx)
    },
  },
  extraReducers(builder) {
    builder.addCase('CLEAR_STATE', () => {
      return []
    })
  },
})

const cartReducer = cartSlice.reducer

export const { caseReducers } = cartSlice

export const { addToCart, modifyQuantity, emptyCart, removeFromCart } =
  cartSlice.actions

export default cartReducer
