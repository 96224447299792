import { FC } from 'react'

import styles from './empty-cart-button.module.scss'
import { emptyCart } from '../cart.slice'
import { useDispatch } from '@/app/store'

const EmptyCartButton: FC = () => {
  const dispatch = useDispatch()
  return (
    <button
      className={styles.root}
      onClick={() => {
        dispatch(emptyCart())
      }}
    >
      Vider le panier
    </button>
  )
}

export default EmptyCartButton
