import { FC } from 'react'
import Link from 'next/link'
import { FiShoppingCart } from 'react-icons/fi'

import styles from './cart-link.module.scss'
import useCartLength from './use-cart-length.hook'

const CartLink: FC = () => {
  const cartLength = useCartLength()
  return (
    <Link href="/cart" title="Panier" className={styles['cart-link']}>
      <FiShoppingCart size={20} />
      {cartLength > 0 && <span className={styles.pill}>{cartLength}</span>}
    </Link>
  )
}

export default CartLink
