/**
 * Formats price number (centimes) into string (euros)
 * @param price Price number in centimes
 * @returns Price formatted in string euros
 */
export const numberToPrice = (price: number): string => {
  if (Number.isInteger(price) === false)
    throw new Error('Non integer value not allowed')

  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(price / 100)
}

/**
 *  Converts a price string into number
 * @param priceStr Price string (euros)
 * @returns Price number (centimes)
 */
export const priceToNumber = (priceStr: string): number => {
  const price = Number(extractPriceFromString(priceStr)) * 100
  return Math.round(price) || 0
}

/**
 * Extract a price amount from a string
 *
 * @param price
 * @returns String representing the price extracted
 */
export const extractPriceFromString = (priceString: string) => {
  const regex = /^(-?\d+(?:\.?)(?:\d{0,2})?)/
  const cleanedPriceString = priceString
    // Keep only price relevant characters
    .replaceAll(/[^.,\-e€\d]/g, '')
    // Allowed decimal operators
    .replaceAll('e', '.')
    .replaceAll('€', '.')
    .replaceAll(',', '.')
    // Remove leading zeros
    .replaceAll(/^(-)?0+?([1-9].*)/g, '$1$2')
    // Add missing zero unit
    .replaceAll(/^\.+?(\d.*)/g, '0.$1')
    // Deduplicate dots
    .replaceAll(/\.+/g, '.')
    // Remove trailing dot
    .replace(/\.$/, '')

  const price = cleanedPriceString.match(regex)?.[0]

  return price ?? '0'
}
