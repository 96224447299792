/* eslint-disable react/display-name */
import { ChangeEventHandler, forwardRef, ReactNode } from 'react'

import styles from './input.module.scss'
import type { FormElement } from '@/types'

import FormControl from '../form-control'
import Label from '../label'

interface InputProps extends FormElement {
  children: ReactNode
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined
  placeholder?: string | undefined
  value?: string | number | undefined
  defaultValue?: string | number | undefined
  rootClass?: string
  autocomplete?: HTMLInputElement['autocomplete']
  form?: string
  list?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    children,
    defaultValue,
    disabled = false,
    max,
    min,
    name,
    onChange,
    placeholder,
    readOnly = false,
    required,
    type = 'text',
    value,
    rootClass = '',
    autocomplete,
    form,
    list,
  } = props
  const className = rootClass ? styles.input + ' ' + rootClass : styles.input
  return (
    <FormControl>
      <Label htmlFor={name}>{children}</Label>
      <input
        className={className}
        disabled={disabled}
        id={name}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        ref={ref}
        type={type}
        value={value}
        defaultValue={defaultValue}
        min={min}
        max={max}
        autoComplete={autocomplete}
        form={form}
        list={list}
      />
    </FormControl>
  )
})
Input.displayName = 'Input'

export default Input
