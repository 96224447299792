import { api } from '@/api/api'
import type { PromotionType } from '@/types'

export const promotionTypesApi = api.injectEndpoints({
  endpoints(builder) {
    return {
      getPromotionTypes: builder.query<PromotionType[], void>({
        query() {
          return '/promotion_types'
        },
        providesTags: ['Promotion Types'],
      }),
    }
  },
})

export const { useGetPromotionTypesQuery } = promotionTypesApi
