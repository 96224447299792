import { api } from '@/api/api'
import type { ClientVariable } from '@/types'

export const clientVariableApi = api.injectEndpoints({
  endpoints(builder) {
    return {
      getClientVariables: builder.query<ClientVariable[], void>({
        query() {
          return '/client_variables'
        },
        providesTags: ['Client variables'],
      }),
    }
  },
})

export const { useGetClientVariablesQuery } = clientVariableApi
