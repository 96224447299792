import { numberToPrice } from '@/utils/price'
import { LS_KEY_CART } from './cart.slice'

const updateCart = legacyCart => {
  return legacyCart.map(legacyElement => {
    const { index, format, price, ...element } = legacyElement

    if (!Number.isInteger(element.id)) {
      throw 'Invalid cart element'
    }

    if (typeof format === 'string') {
      element.format = { slug: format, name: format }
    } else if (format) {
      element.format = format
    }

    if (Number.isInteger(price)) {
      element.price = numberToPrice(price)
    } else if (price) {
      element.price = price
    }

    return element
  })
}

/*
 * Migrate persisted cart object from legacy app to nextJS
 * TODO: To remove 6 months after NextJS deploy.
 */
const migrateLegacyCart = legacyCart => {
  let cart
  try {
    cart = updateCart(legacyCart)
    return cart
  } catch (error) {
    console.error(`Legacy cart migration failed
      legacy cart: ${JSON.stringify(legacyCart)}
      Error: ${error}
    `)
    cart = []
    return cart
  } finally {
    // TODO: Do not clear legacy cart from storage for now
    // localStorage.removeItem('cart')
    localStorage.setItem(LS_KEY_CART, JSON.stringify(cart))
  }
}

export default migrateLegacyCart
