import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import isEqual from 'lodash.isequal'

import {
  caseReducers as cartReducers,
  LS_KEY_CART,
} from '@/feature/cart/cart.slice'

export const rtkQueryErrorLogger: Middleware = () => next => action => {
  if (isRejectedWithValue(action)) {
    console.error(`Redux action failed. Action type: ${action.type}`)
  }
  return next(action)
}

const { addToCart, modifyQuantity, emptyCart, removeFromCart } = cartReducers

export const persistCartMiddleware: Middleware =
  ({ getState }) =>
  next =>
  action => {
    const prevCart = getState().cart
    const result = next(action)
    const nextCart = getState().cart

    // @ts-ignore
    switch (action.type) {
      case 'CLEAR_STATE':
        /*
         * Do not clear cart on logout.
         * Users expect it to be persisted.
         */
        break
      case `cart/${addToCart.name}`:
      case `cart/${modifyQuantity.name}`:
      case `cart/${emptyCart.name}`:
      case `cart/${removeFromCart.name}`: {
        if (isEqual(prevCart, nextCart) === false) {
          localStorage.setItem(LS_KEY_CART, JSON.stringify(nextCart))
          break
        }
      }
    }

    return result
  }
