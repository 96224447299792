import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import fetcher from '@/utils/fetcher'
import type { ErrorMessage, Status } from '@/types'

export interface User {
  clientId: number
  id: number
  isAdmin: boolean
  mail: string
  role: 'pharmacy' | 'association'
}

interface RawUser extends Omit<User, 'clientId' | 'isAdmin'> {
  client_id: number
  is_admin: boolean
}

export const fetchUser = createAsyncThunk<
  User,
  void,
  { rejectValue: { error: { message: string } } }
>('user/fetchUser', async (_, { rejectWithValue }) => {
  try {
    const {
      client_id: clientId,
      id,
      is_admin: isAdmin,
      mail,
    } = await fetcher<RawUser>('/api/user')
    return {
      clientId,
      id,
      isAdmin,
      mail,
      role: isAdmin ? 'association' : 'pharmacy',
    }
  } catch (err) {
    const error = err as Error
    console.error(error)
    return rejectWithValue({
      error: {
        message: error?.message || 'Get user request failed',
      },
    })
  }
})

interface UserState {
  user: User | null
  status: Status
  error: ErrorMessage
}
const initialState: UserState = {
  user: null,
  status: 'idle',
  error: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUser.pending, state => {
        state.status = 'pending'
        state.error = null
        state.user = null
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = 'success'
        state.error = null
        state.user = action.payload
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = 'error'
        state.error = action.payload?.error.message
        state.user = null
      })
  },
})

const userReducer = userSlice.reducer

export default userReducer
