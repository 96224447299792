import { api } from '@/api/api'
import type { PromotionVariable } from '@/types'

export const promotionVariableApi = api.injectEndpoints({
  endpoints(builder) {
    return {
      getPromotionVariables: builder.query<PromotionVariable[], void>({
        query() {
          return '/promotion_variables'
        },
        providesTags: ['Promotion variables'],
      }),
      updatePromotionVariables: builder.mutation<
        PromotionVariable,
        Omit<PromotionVariable, 'id'>
      >({
        query(promotionVariable) {
          return {
            url: '/promotion_variables',
            method: 'POST',
            body: {
              promotionVariable,
            },
          }
        },
        invalidatesTags: ['Promotion variables'],
      }),
    }
  },
})

export const {
  useGetPromotionVariablesQuery,
  useUpdatePromotionVariablesMutation,
} = promotionVariableApi
