/* eslint-disable max-len */
import { MdEdit } from 'react-icons/md'

import { Navlink } from '@/types'
import { ArchiveSVG, DocumentSVG, FolderSVG } from '@/shared/svg-icons'

export const OTHER_DOCUMENT_TAB_NAME = 'Autres documents'

export const BLUR_DATA_URL =
  // eslint-disable-next-line max-len
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPsnDy5HgAFjAIwSY//IAAAAABJRU5ErkJggg=='

export const CAMPAIGN_MAX_STOP_DATE = '2999-12-31'

export const COMM_POSTER_BASE_URL = '/api/communications/posters/'
export const COMM_POSTER_EXT = '.png'

export const DOCUMENT_BASE_URL = '/api/documents/posters/'
export const DOCUMENT_EXT = '.png'

export const PROMOTION_BASE_URL = '/api/promotions/posters/'
export const PROMOTION_EXT = '.png'

export const CART_DOWNLOAD_URL = '/api/cart.pdf'

export const ROLES = {
  pharmacy: ['pharmacy'],
  association: ['association'],
  all: ['pharmacy', 'association'],
}

export const ROUTES = {
  '/': {
    access: ROLES.all,
  },
  '/current/promotions': {
    access: ROLES.pharmacy,
  },
  '/archive/campaigns': {
    access: ROLES.all,
  },
  '/archive/promotions': {
    access: ROLES.pharmacy,
  },
  '/documents': {
    access: ROLES.all,
  },
  '/draft/campaigns': {
    access: ROLES.association,
  },
  '/account': {
    access: ROLES.all,
  },
  '/promotion/edit/[id]': {
    access: ROLES.all,
  },
  '/promotion/view/[id]': {
    access: ROLES.pharmacy,
  },
  '/document/edit/[id]': {
    access: ROLES.association,
  },
  '/communication/view/[id]': {
    access: ROLES.pharmacy,
  },
  '/communication/edit/[id]': {
    access: ROLES.association,
  },
  '/cart': {
    access: ROLES.pharmacy,
  },
} as const

export const PHARMACY_NAVLINKS: Navlink[] = [
  {
    href: '/',
    activeAt: ['/current/campaigns', '/current/promotions'],
    text: 'Campagnes et animations',
    name: 'current',
    icon: <FolderSVG />,
  },
  {
    href: '/archive',
    activeAt: ['/archive/campaigns', '/archive/promotions'],
    text: 'Archives',
    name: 'archive',
    icon: <ArchiveSVG />,
  },
  {
    href: '/documents',
    activeAt: ['/documents'],
    text: 'Documents',
    name: 'document',
    icon: <DocumentSVG />,
  },
]

export const ASSOCIATION_NAVLINKS: Navlink[] = [
  {
    href: '/',
    activeAt: ['/current/campaigns', '/current/promotions'],
    text: 'En ligne',
    name: 'current',
    icon: <FolderSVG />,
  },
  {
    href: '/archive/campaigns',
    activeAt: ['/archive/campaigns', '/archive/promotions'],
    text: 'Terminé',
    name: 'archive',
    icon: <ArchiveSVG />,
  },
  {
    href: '/draft/campaigns',
    activeAt: ['/draft/campaigns'],
    text: 'À venir',
    name: 'draft',
    icon: <MdEdit />,
  },
  {
    href: '/documents',
    activeAt: ['/documents'],
    text: 'Documents',
    name: 'document',
    icon: <DocumentSVG />,
  },
]

export const NAVLINKS = {
  pharmacy: PHARMACY_NAVLINKS,
  association: ASSOCIATION_NAVLINKS,
}

export const SEARCHBAR_ALLOWED_PAGES = [
  '/',
  '/archive/campaigns',
  '/draft/campaigns',
  '/current/promotions',
  '/archive/promotions',
]
