import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const searchbarSlice = createSlice({
  name: 'search',
  initialState: '',
  reducers: {
    setSearch(_search, action: PayloadAction<string>) {
      return action.payload
    },
  },
})

const searchbarReducer = searchbarSlice.reducer
export const { setSearch } = searchbarSlice.actions
export default searchbarReducer
