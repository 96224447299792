import { FC, useEffect, useMemo, useState } from 'react'
import Link from 'next/link'

import styles from './cart.module.scss'
import useCartLength from './use-cart-length.hook'
import Heading from '@/shared/heading/heading'
import { computeCartLengthLabel } from './cart.helpers'
import { useSelector } from '@/app/store'
import { groupBy } from '@/utils/group-by'
import type { ElementToAddInCart } from '@/types'
import {
  CART_DOWNLOAD_URL,
  COMM_POSTER_BASE_URL,
  COMM_POSTER_EXT,
  DOCUMENT_BASE_URL,
  DOCUMENT_EXT,
  PROMOTION_BASE_URL,
  PROMOTION_EXT,
} from '@/constants'
import ModifyElementQuantity from './modify-element-quantity'
import EmptyCartButton from './empty-cart-button'
import RemoveFromCartButton from './remove-from-cart-button'

const CART_FORM_ID = 'cart-form'

const getCartElementImgUrl = (
  type: ElementToAddInCart['type'],
  elementId: number,
  format?: string
) => {
  switch (type) {
    case 'promotion':
      return `${PROMOTION_BASE_URL}${format}/${elementId}${PROMOTION_EXT}`
    case 'document':
      return `${DOCUMENT_BASE_URL}${elementId}${DOCUMENT_EXT}`
    case 'communication':
      return `${COMM_POSTER_BASE_URL}${elementId}${COMM_POSTER_EXT}`
  }
}

const Header = () => {
  const cartLength = useCartLength()
  const [cartLengthLabel, setCartLengthLabel] = useState(() =>
    computeCartLengthLabel(cartLength)
  )
  useEffect(() => {
    setCartLengthLabel(computeCartLengthLabel(cartLength))
  }, [cartLength])

  return (
    <header className={styles.header}>
      <Heading as="h1" className={styles.title}>
        Panier
      </Heading>
      <span className={styles['cart-length-label']}>{cartLengthLabel}</span>
    </header>
  )
}

const CartTable = () => {
  const cart = useSelector(state => state.cart)
  const cartGroupedByName = useMemo(() => {
    return groupBy(cart, 'name')
  }, [cart])

  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.tr}>
          <th className={styles.th}>Titre</th>
          <th className={styles.th}>Affiche</th>
          <th className={styles.th}>Format</th>
          <th className={styles.th}>Quantité</th>
          <th className={styles.th}>Actions</th>
        </tr>
      </thead>
      {Array.from(cartGroupedByName.keys()).map(key => {
        return (
          <tbody key={key} className={styles.tbody}>
            {cartGroupedByName.get(key)?.map(cartElement => {
              const { id, type, format, quantity, name } = cartElement
              const imgUrl = getCartElementImgUrl(type, id, format?.slug)
              return (
                <tr key={`${id}-${format?.slug}-${type}`} className={styles.tr}>
                  <td className={styles.td}>{name}</td>
                  <td className={styles.td}>
                    <img
                      alt={cartElement.name}
                      src={imgUrl}
                      className={styles.img}
                    />
                  </td>
                  <td className={styles.td}>{format?.name ?? 'N/A'}</td>
                  <td className={styles.td}>
                    <ModifyElementQuantity
                      currentQuantity={quantity}
                      cartElement={cartElement}
                      cartFormId={CART_FORM_ID}
                    />
                  </td>
                  <td className={`${styles.td} ${styles['remove-from-cart']}`}>
                    <RemoveFromCartButton elementToRemove={cartElement} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        )
      })}
    </table>
  )
}

const CartButtons = () => {
  return (
    <div className={styles['cart-buttons']}>
      <button
        type="submit"
        form={CART_FORM_ID}
        className={styles['download-btn']}
      >
        Télécharger
      </button>
      <Link href="/" className={styles['go-home-link']}>
        Continuer ma sélection
      </Link>
      <EmptyCartButton />
    </div>
  )
}

const Cart: FC = () => {
  const cartLength = useCartLength()
  return (
    <main className={styles.root}>
      <Header />
      {cartLength > 0 ? (
        <form
          method="POST"
          action={CART_DOWNLOAD_URL}
          id={CART_FORM_ID}
          className={styles['cart-container']}
        >
          <CartTable />
          <CartButtons />
        </form>
      ) : (
        <p>Le panier est vide</p>
      )}
    </main>
  )
}

export default Cart
