import { api } from '@/api/api'
import type { PromotionBadge, PromotionBadgeMutationPayload } from '@/types'

export const promotionBadgeApi = api.injectEndpoints({
  endpoints(builder) {
    return {
      getPromotionBadges: builder.query<PromotionBadge[], void>({
        query() {
          return '/promotion_badges'
        },
        providesTags: ['Promotion Badges'],
      }),
      addPromotionBadge: builder.mutation<
        PromotionBadge,
        PromotionBadgeMutationPayload
      >({
        query({ badgeId, promotionId }) {
          return {
            method: 'POST',
            url: '/promotion_badges',
            body: {
              badge_id: badgeId,
              promotion_id: promotionId,
            },
          }
        },
        invalidatesTags: ['Promotion Badges'],
      }),
      deletePromotionBadge: builder.mutation<
        unknown,
        PromotionBadgeMutationPayload
      >({
        query({ badgeId, promotionId }) {
          return {
            method: 'DELETE',
            url: `/promotion_badges/${promotionId}/${badgeId}`,
          }
        },
        invalidatesTags: ['Promotion Badges'],
      }),
    }
  },
})

export const {
  useGetPromotionBadgesQuery,
  useAddPromotionBadgeMutation,
  useDeletePromotionBadgeMutation,
} = promotionBadgeApi
