import { api } from '@/api/api'
import type { Communication } from '@/types'
import { excludeById, printException } from '@/api/api.helpers'

export const communicationApi = api.injectEndpoints({
  endpoints(builder) {
    return {
      getCommunications: builder.query<Communication[], void>({
        query() {
          return '/communications'
        },
        providesTags: ['Communication'],
      }),
      addCommunication: builder.mutation<
        Communication,
        { category?: string; campaignId: number }
      >({
        query({ campaignId, category }) {
          return {
            url: '/communications',
            method: 'POST',
            body: {
              category,
              campaign_id: campaignId,
            },
          }
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            dispatch(
              communicationApi.util.updateQueryData(
                'getCommunications',
                undefined,
                function (draft) {
                  draft.push(data)
                }
              )
            )
          } catch (exception) {
            printException(
              exception,
              'Unknown exception : communication creation'
            )
          }
        },
      }),
      editCommunication: builder.mutation<Communication, Communication>({
        query(communication) {
          return {
            url: `/communications/${communication.id}`,
            method: 'PUT',
            body: {
              communication,
            },
          }
        },
        invalidatesTags: ['Communication'],
      }),
      deleteCommunication: builder.mutation<void, number>({
        query(communicationId) {
          return {
            url: `/communications/${communicationId}`,
            method: 'DELETE',
          }
        },
        async onQueryStarted(communicationId, { dispatch, queryFulfilled }) {
          const optimisticResult = dispatch(
            communicationApi.util.updateQueryData(
              'getCommunications',
              undefined,
              function (draft) {
                return draft.filter(excludeById(communicationId))
              }
            )
          )
          try {
            await queryFulfilled
          } catch (exception) {
            optimisticResult.undo()
            printException(
              exception,
              'Unknown exception : communication deletion'
            )
          }
        },
      }),
    }
  },
})

export const {
  useGetCommunicationsQuery,
  useAddCommunicationMutation,
  useDeleteCommunicationMutation,
  useEditCommunicationMutation,
} = communicationApi
