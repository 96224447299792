import { FC, ReactNode } from 'react'

import styles from './label.module.scss'

interface LabelProps {
  htmlFor?: string | undefined
  children: ReactNode
}

const Label: FC<LabelProps> = ({ children, htmlFor }) => {
  return (
    <label className={styles.label} htmlFor={htmlFor}>
      {children}
    </label>
  )
}

export default Label
