import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {
  TypedUseSelectorHook,
  useDispatch as _useDispatch,
  useSelector as _useSelector,
} from 'react-redux'

import authReducer from '@/feature/auth/auth.slice'
import clientReducer from '@/feature/client/client.slice'
import cartReducer from '@/feature/cart/cart.slice'
import posterUrlKeyReducer from '@/feature/promotions/poster-url-key.slice'
import searchbarReducer from '@/feature/search-bar/search-bar.slice'
import userReducer from '@/feature/user/user.slice'
import { api } from '@/api'
import { rtkQueryErrorLogger, persistCartMiddleware } from './middleware'

// https://stackoverflow.com/a/61943631
const appReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  client: clientReducer,
  posterUrlKey: posterUrlKeyReducer,
  user: userReducer,
  search: searchbarReducer,
  [api.reducerPath]: api.reducer,
})

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'CLEAR_STATE') {
    state = undefined
  }
  return appReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      rtkQueryErrorLogger,
      api.middleware,
      persistCartMiddleware
    ),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useDispatch = () => _useDispatch<AppDispatch>()
export const useSelector: TypedUseSelectorHook<RootState> = _useSelector

export default store
