/* eslint-disable max-len */
import { SVGProps } from 'react'

const CartSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M26.5 40.2c-1.4 0-2.6 1.2-2.6 2.6 0 1.4 1.2 2.6 2.6 2.6s2.6-1.2 2.6-2.6c0-1.4-1.2-2.6-2.6-2.6zm0 3.8c-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .5-.5 1.1-1.1 1.1zM35.6 40.2c-1.4 0-2.6 1.2-2.6 2.6 0 1.4 1.2 2.6 2.6 2.6 1.4 0 2.6-1.2 2.6-2.6 0-1.4-1.2-2.6-2.6-2.6zm0 3.8c-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1.1.5-.5 1.1-1.1 1.1zM36.2 26.5H25.8c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h10.4c.4 0 .7-.3.7-.7.1-.4-.2-.7-.7-.7zM35.7 30.3h-9.3c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h9.3c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7z" />
    <path d="M43.3 23.3c-.3-.3-.7-.5-1.1-.5H19.6l-.5-2.2c-.1-.5-.4-.9-.8-1l-3.2-1.4c-.4-.2-.8 0-1 .4-.2.4 0 .8.4 1l3.2 1.4 3.5 17.1c.1.7.7 1.2 1.4 1.2H40c.4 0 .7-.3.7-.7s-.3-.7-.7-.7H22.6l-.4-2.1h18c.7 0 1.3-.5 1.4-1.2l2-9.9c.1-.7 0-1.1-.3-1.4zM40.2 34H21.9l-2-9.9h22.3l-2 9.9z" />
  </svg>
)

export default CartSVG
