import { SVGProps } from 'react'

const AddSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M43.8 26.9V34H33.7v10h-7.4v-9.9h-10V27h10V17h7.4v10h10.1z" />
  </svg>
)

export default AddSVG
