import { api } from '@/api/api'
import type { IDocument } from '@/types'

export const documentApi = api.injectEndpoints({
  endpoints(builder) {
    return {
      getDocument: builder.query<IDocument[], void>({
        query() {
          return '/documents'
        },
        providesTags: ['Document'],
      }),
      addDocument: builder.mutation<
        IDocument,
        { category: string; clientId: number }
      >({
        query({ clientId, category }) {
          return {
            url: '/documents',
            method: 'POST',
            body: {
              category,
              client_id: clientId,
            },
          }
        },
        invalidatesTags: ['Document'],
      }),
      deleteDocument: builder.mutation<void, number>({
        query(documentId) {
          return {
            method: 'DELETE',
            url: `/documents/${documentId}`,
          }
        },
        invalidatesTags: ['Document'],
      }),
      editDocument: builder.mutation<IDocument, IDocument & { data?: string }>({
        query(document) {
          return {
            url: `/documents/${document.id}`,
            method: 'PUT',
            body: {
              document,
            },
          }
        },
        invalidatesTags: ['Document'],
      }),
    }
  },
})

export const {
  useGetDocumentQuery,
  useEditDocumentMutation,
  useDeleteDocumentMutation,
  useAddDocumentMutation,
} = documentApi
