import { api } from '@/api/api'
import type { PosterFormat } from '@/types'

export const posterFormatApi = api.injectEndpoints({
  endpoints(builder) {
    return {
      getPosterFormats: builder.query<PosterFormat[], void>({
        query() {
          return '/poster_formats'
        },
        providesTags: ['Poster formats'],
      }),
    }
  },
})

export const { useGetPosterFormatsQuery } = posterFormatApi
