import { FC } from 'react'
import { MdDeleteForever } from 'react-icons/md'

import IconButton from '@/shared/icon-button'
import { useDispatch } from '@/app/store'
import { removeFromCart } from '../cart.slice'
import type { CartElement } from '@/types'

const RemoveFromCartButton: FC<{ elementToRemove: CartElement }> = ({
  elementToRemove,
}) => {
  const dispatch = useDispatch()
  return (
    <IconButton
      aria-label="Supprimer"
      title="Supprimer"
      icon={<MdDeleteForever size={25} />}
      onClick={() => {
        dispatch(removeFromCart(elementToRemove))
      }}
    />
  )
}

export default RemoveFromCartButton
